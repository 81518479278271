import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import guillaumeBackground from "../../images/guillaume_lafond_images/Biographie.jpg";


const Container = styled.div`
  ${tw`relative bg-center bg-no-repeat bg-cover h-screen `}
  background-image: url("${guillaumeBackground}");
  padding-top: 200px; /* Adjust if necessary to avoid overlap with the header */

  /* Default background properties */
  background-size: cover;
  background-position: center center;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  /* Adjusting height */
  height: 100vh; /* Sets the height to be at least the full viewport height */
  min-height: 500px; /* Sets a minimum height for the image */

  /* Media Query for screens smaller than 500px */
  @media (max-width: 2000px) {
    background-size: cover; /* Continue using cover for a zoom effect */
    background-position: 47%; /* Adjusts to focus on 1/3 of the image */
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative mx-auto h-full flex flex-col`;

const BioTextContainer = styled.div`
  ${tw`p-8 md:p-12 lg:p-16 bg-[rgba(175,155,135,0.6)] rounded-lg shadow-xl`}
  position: absolute; 
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px; 
  width: 90%;
  z-index: 30;
  text-align: justify;
  overflow-y: auto;
  max-height: 80%;
  color: black;
  font-size: 1.125rem; // Start with a slightly bigger font size for the base

  @media (max-width: 1024px) {
    font-size: 1.3rem; // Adjust font size for tablets
  }

  @media (max-width: 768px) {
    font-size: 1.2rem; // Adjust font size for small tablets
  }

  @media (max-width: 640px) {
    font-size: 1rem; // Adjust font size for large phones
  }

  @media (max-width: 480px) {
    font-size: 0.85rem; // Adjust font size for small phones
    padding: 4px; // Adjust padding for small phones
  }
`;


export default () => {
    return (
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <BioTextContainer>
            <p style={{ textIndent: '20px' }}>
            Avec son timbre de voix chaleureux et son amour du country bien ancré, Guillaume Lafond s’est taillé une place de choix dans le paysage musical québécois. 
            Après avoir fait ses débuts sur des croisières ainsi que dans les bars et restaurants de sa région natale en Montérégie, il se fait remarquer lors de Star Académie 2021, où il devient l’un des favoris du public. Cette aventure marque un tournant dans sa carrière et mène à la sortie de son premier album, À destination, en octobre 2022. Porté par neuf compositions inspirées de son vécu, l’album lui ouvre les portes des plus grandes scènes et festivals du Québec. 
            Il assure également les premières parties de la tournée de Guylaine Tanguay et se distingue avec cinq nominations au Gala Country 2023, en plus d’une nomination à l’ADISQ pour l’Album country de l’année. En 2024, Guillaume poursuit sur sa lancée avec une nouvelle nomination au Gala Country dans la catégorie Interprète masculin de l’année. Avec son groupe, il a foulé les scènes de nombreux festivals renommés, dont le Farwest’ival et le Festival Country de Lotbinière. 
            Authentique et proche de son public, Guillaume Lafond fait sa place avec un country qui lui ressemble : sincère et rassembleur.
            </p>
            <p style={{ textIndent: '20px' }}>
            Parallèlement, il s’illustre au sein de Grande Ourse, un projet collectif réunissant sept artistes country émergents. 
            Leur album, lancé en septembre 2023, décroche une nomination à l’ADISQ pour l’Album de l’année – Country contemporain, tandis que la chanson Au Nord Bar est sacrée Chanson de l’année au Gala Country 2024. 
            En avril 2025, Guillaume lancera son deuxième album, Sous mon chapeau. Avec des extraits comme Du temps pour toi, Coup de poing et Mon chapeau, il promet un album aux sonorités country authentiques, à la fois entraînantes et enveloppantes, parfait pour autant se laisser bercer sur la galerie que se déhancher sur la piste de danse.
            </p>
          </BioTextContainer>
        </HeroContainer>
      </Container>
    );
  };
  

